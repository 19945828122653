const SecGroup = () => {

  return (
    <div className="col-lg-8 col-lg-offset-2 col-md-8 offset-md-2 col-xs-12 text-center">
        <div className="group">
            <form action="https://loansharksolutions.us13.list-manage.com/subscribe/post?u=d3ee948febc0ac0e8e502f13e&amp;id=c87e8c8f98" method="post">
                <button id={"subscribeButton"}>Subscribe</button>
            </form>
        </div>
    </div>
  );
}

export default SecGroup;
