import './SectionHeading.css'

const SectionHeading = () => {

  return (
    <div className="section-heading text-center" data-aos="fade-up" id="subscribeSection">
        <h2 className="title-head-subscribe"><b>$</b>SHARK</h2>
        <h2 id="mailing-list-text">Mailing List</h2>
    </div>
  );
}

export default SectionHeading;
