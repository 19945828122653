import {
    HomeDemo2IcoPlatforms1,
    HomeDemo2IcoPlatforms2,
    HomeDemo2IcoPlatforms3,
    HomeDemo2IcoPlatforms4,
    HomeDemo2IcoPlatforms5,
    HomeDemo2IcoPlatforms6,

    HomeDemo2Services1,
    HomeDemo2Services2,
    HomeDemo2Services3,
    HomeDemo2Services4,
    HomeDemo2Services5,
    HomeDemo2Services6,

    HomeDemo2TokenIcon1,

    HomeDemo2TeamImg1,
    HomeDemo2TeamImg2,
    HomeDemo2TeamImg3,
    HomeDemo2TeamImg4,

    HomeDemo2Partners1,
    HomeDemo2Partners2,
    HomeDemo2Partners3,
    HomeDemo2Partners4,
    HomeDemo2Partners5,
    HomeDemo2Partners6,
    HomeDemo2Partners7,
    HomeDemo2Partners8,
    HomeDemo2Partners9,
    HomeDemo2Partners10,
    HomeDemo2Partners11,
    HomeDemo2Partners12,

    HomeDemo2RoadmapIcons1,
    HomeDemo2RoadmapIcons2,
    HomeDemo2RoadmapIcons3,
    HomeDemo2RoadmapIcons4,
    HomeDemo2RoadmapIcons5,
    HomeDemo2RoadmapIcons6,
    HomeDemo2RoadmapIcons7,
    HomeDemo2RoadmapIcons8,
    HomeDemo2RoadmapIcons9,

    HomeDemo2BlogImg1,
    HomeDemo2BlogImg2,
    HomeDemo2BlogImg3

} from '../../utils/allImgs'

import TeamShark1 from "../../assets/img/team-img/shark-2.png"
import TeamShark2 from "../../assets/img/team-img/shark-6.png"
import TeamShark3 from "../../assets/img/team-img/shark-3.png"
import TeamShark4 from "../../assets/img/team-img/shark-4.png"
import TeamShark5 from "../../assets/img/team-img/shark-5.png"
import TeamShark6 from "../../assets/img/team-img/shark-7.png"


export const VerticalSocial = [
    {nameIco:'fa fa-reddit', href: 'https://www.reddit.com/r/loansharksolutions/'},
    {nameIco:'fa fa-youtube', href: 'https://www.youtube.com/user/cameronmu917772'},
    {nameIco:'fa fa-twitter', href: 'https://twitter.com/loansharkinc?s=21'},
    {nameIco:'fa fa-telegram', href: 'https://t.me/+iHLy9yWFiylmY2Fk'}
]

export const SingleCoolFact = [
    {
        img:HomeDemo2IcoPlatforms1,
        ico_check:true
    },
    {
        img:HomeDemo2IcoPlatforms2,
        ico_check:false
    },
    {
        img:HomeDemo2IcoPlatforms3,
        ico_check:true
    },
    {
        img:HomeDemo2IcoPlatforms4,
        ico_check:true
    },
    {
        img:HomeDemo2IcoPlatforms5,
        ico_check:false
    },
    {
        img:HomeDemo2IcoPlatforms6,
        ico_check:true
    }
]

export const service_single_content = [
    {
        img:HomeDemo2Services3,
        title:'NFT Loans',
        text: 'Users will have the ability to create P2P loans utilizing smart contracts, and collateralizing using their own digital assets. A one-stop-shop with a simple user interface, and multiple inter-changeable options, all dependent on their individual needs.'
    },
    {
        img:HomeDemo2Services2,
        title:'Smart Contracts',
        text: 'Stellar Lumens (XLM) based smart contracts will allow Loan Shark Solutions to facilitate all transactions on terms set by, and agreed by all parties.'
    },
    {
        img:HomeDemo2Services1,
        title:'Decentraland Auction House',
        text: 'Our auction house for defaulted lent NFTs exists strictly in the Metaverse, and will be easily accessible no matter where you are on the planet.'
    },
    {
        img:HomeDemo2Services4,
        title:'Social Events',
        text: 'Featuring live bands, comedy shows, and talented artists of all varieties, Loan Shark Solutions, Inc. will create a lively space for people all around the globe to interact with, and experience culture in an exciting new way.'
    },
    {
        img:HomeDemo2Services5,
        title:'NFT Tattoo Art Gallery',
        text: 'A real-world asset, with blockchain certification! Loan Shark Solutions, Inc. will establish a gallery to browse and purchase one of a kind masterpieces. A user\'s tattoo artist of choice will then be able to easily access the art to stencil and tattoo directly onto the user.'
    },
    {
        img:HomeDemo2Services6,
        title:'Loan Shark Solutions\' Store',
        text: 'As well as providing a great place to aggregate your transactions, Loan Shark Solutions, Inc. will be minting our very own NFTs. From concert tickets, to one of a kind artwork, music, and more, all will be backed up with the network of technology that is driving the future of finance.'
    }
]

export const timelineInfo = [
    {
        left:"10%",
        ClassName:"data_cir cir_0",
        date:"Q1 2020",
        isCompleted: true,
        IsSpan:true,
        TextSpan:"Emergence and design of the idea",
        IsTowLi:false,
        TextTowLi1:"",
        TextTowLi2:"",
        IsThreeLi:false,
        TextThreeLi1:"",
        TextThreeLi2:"",
        TextThreeLi3:"",
        IsFourLi:false,
        TextFourLi1:"",
        TextFourLi2:"",
        TextFourLi3:"",
        TextFourLi4:"",
        img:HomeDemo2RoadmapIcons1

    },
    {
        left:"20%",
        ClassName:"data_cir cir_1",
        date:"Q2 2020",
        isCompleted: false,
        IsSpan:true,
        TextSpan:"Built our team",
        IsTowLi:false,
        TextTowLi1:"",
        TextTowLi2:"",
        IsThreeLi:false,
        TextThreeLi1:"",
        TextThreeLi2:"",
        TextThreeLi3:"",
        IsFourLi:false,
        TextFourLi1:"",
        TextFourLi2:"",
        TextFourLi3:"",
        TextFourLi4:"",
        img:HomeDemo2RoadmapIcons2

    },
    {
        left:"30%",
        ClassName:"data_cir cir_2",
        date:"Q3-Q4 2020",
        isCompleted: false,
        IsSpan:false,
        TextSpan:"",
        IsTowLi:false,
        TextTowLi1:'',
        TextTowLi2:'',
        IsThreeLi:true,
        TextThreeLi1:"market research",
        TextThreeLi2:"concept & development",
        TextThreeLi3:"core development & infrastructure",
        IsFourLi:false,
        TextFourLi1:"",
        TextFourLi2:"",
        TextFourLi3:"",
        TextFourLi4:"",
        img:HomeDemo2RoadmapIcons3

    },
    {
        left:"40%",
        ClassName:"data_cir cir_3",
        date:"Q1 2021",
        isCompleted: false,
        IsSpan:true,
        TextSpan:"Consolidation of team",
        IsTowLi:false,
        TextTowLi1:"",
        TextTowLi2:"",
        IsThreeLi:false,
        TextThreeLi1:"website launch",
        TextThreeLi2:"White Paper published",
        TextThreeLi3:"Private and Pre-sale start",
        IsFourLi:false,
        TextFourLi1:"",
        TextFourLi2:"",
        TextFourLi3:"",
        TextFourLi4:"",
        img:HomeDemo2RoadmapIcons4

    },
    {
        left:"50%",
        ClassName:"data_cir cir_4 red_c",
        date:"Q3 2021",
        isCompleted: false,
        IsSpan:false,
        TextSpan:"",
        IsTowLi:false,
        TextTowLi1:"",
        TextTowLi2:"",
        IsThreeLi:true,
        TextThreeLi1:"Built & minted $SHARK token",
        TextThreeLi2:"$SHARK private sale",
        TextThreeLi3:"Established Loan Shark Solutions Incorporated",
        IsFourLi:false,
        TextFourLi1:"",
        TextFourLi2:"",
        TextFourLi3:"",
        TextFourLi4:"",
        img:HomeDemo2RoadmapIcons5

    },
    {
        left:"60%",
        ClassName:"data_cir cir_5",
        isCompleted: false,
        date:"Q1 2022",
        IsSpan:true,
        TextSpan:"Created first draft white paper",
        IsTowLi:false,
        TextTowLi1:"",
        TextTowLi2:"",
        IsThreeLi:true,
        TextThreeLi1:"",
        TextThreeLi2:"",
        TextThreeLi3:" ",
        IsFourLi:false,
        TextFourLi1:"",
        TextFourLi2:"",
        TextFourLi3:"",
        TextFourLi4:"",
        img:HomeDemo2RoadmapIcons6

    },
    {
        left:"70%",
        ClassName:"data_cir cir_6 next",
        isCompleted: false,
        date:"Q3 2022",
        IsSpan:false,
        TextSpan:"",
        IsTowLi:false,
        TextTowLi1:"",
        TextTowLi2:"",
        IsThreeLi:true,
        TextThreeLi1:"Website development",
        TextThreeLi2:"New partnerships",
        TextThreeLi3:"Official pre-sale",
        IsFourLi:false,
        TextFourLi1:"",
        TextFourLi2:"",
        TextFourLi3:"",
        TextFourLi4:"",
        img:HomeDemo2RoadmapIcons7

    },
    {
        left:"80%",
        ClassName:"data_cir cir_7 next",
        isCompleted: false,
        date:"Q3-Q4 2022",
        IsSpan:false,
        TextSpan:"",
        IsTowLi:true,
        TextTowLi1:"Website development of new platform",
        TextTowLi2:"Website testing of new platform",
        IsThreeLi:false,
        TextThreeLi1:"",
        TextThreeLi2:"",
        TextThreeLi3:"",
        IsFourLi:false,
        TextFourLi1:"",
        TextFourLi2:"",
        TextFourLi3:"",
        TextFourLi4:"",
        img:HomeDemo2RoadmapIcons8

    },
    {
        left:"90%",
        ClassName:"data_cir cir_8 next",
        isCompleted: false,
        date:"2023+",
        IsSpan:false,
        TextSpan:"",
        IsTowLi:true,
        TextTowLi1:"Complete platform integration",
        TextTowLi2:"The future of $SHARK",
        IsThreeLi:false,
        TextThreeLi1:"",
        TextThreeLi2:"",
        TextThreeLi3:"",
        IsFourLi:false,
        TextFourLi1:"",
        TextFourLi2:"",
        TextFourLi3:"",
        TextFourLi4:"",
        img:HomeDemo2RoadmapIcons9

    }
]

export const ServiceBlock = [
    {
        classIco:"icon ti-mobile",
        title:"Powerfull Mobile and Online App"
    },
    {
        classIco:"icon ti-widget",
        title:"Brings more Transparency and Speed"
    },
    {
        classIco:"icon ti-settings",
        title:"Special for Multiple Use Capabilities"
    },
]

export const SocialListIco = [
    {icoName:"fa fa-facebook-square"},
    {icoName:"fa fa-twitter-square"},
    {icoName:"fa fa-github-square"},
    {icoName:"fa fa-instagram"},
    {icoName:"fa fa-medium"}
]

export const FQAInfo = [
    {
        text:'$SHARK Objectives',
        ID:'Q1',
        summary: '$SHARK intends to become the new standard for loans related to NFT'
    },
    {
        text:'Pre-sale Rules',
        ID:'Q2',
        summary: 'You must have money to buy the tokens'
    },
    {
        text:'Pre-sale dates',
        ID:'Q3'
    },
    {
        text:'How to buy $SHARK tokens',
        ID:'Q4'
    },
]

export const DocElementTitle = [

]

export const TokenText = [
    {
        text:'Lorem ipsum dolor si amet, conse ctetur elit',
        img:HomeDemo2TokenIcon1
    },
    {
        text:'Sed quis accumsan nisi Ut ut felis',
        img:HomeDemo2TokenIcon1
    },
    {
        text:'felis congue nisl hendrerit commodo',
        img:HomeDemo2TokenIcon1
    },
    {
        text:'arch nemo sequi rem saepe ad quasi ullam.',
        img:HomeDemo2TokenIcon1
    },
]

export const MissionData = [
    {
        icoName:"ti-shine",
        title:"Quality"
    },
    {
        icoName:"ti-ruler-pencil",
        title:"Creativity"
    },
    {
        icoName:"ti-heart",
        title:"Reliability"
    },
]

export const TeamMember = [
    {
        id: 1,
        img:TeamShark1,
        title:'Logan Hailele',
        text:'Chief Executive Officer',
        bio: 'With his innovative thinking and extensive background in team development, Logan looks to explore new boundaries and discover better ways for end users to control their investments. With his sharp eye for talent and attention to detail, Logan has built a team of like minded individuals that will help him bring a vision to life.'
    },
    {
        id: 2,
        img:TeamShark3,
        title:'Cameron M.',
        text:'Chief Operations Officer',
        bio: 'Multiple years in business and customer relations. Management and start ups. Cameron is the Swiss Army knife when it comes to getting the job done!'
    },

    {
        id: 3,
        img:TeamShark2,
        title:'Zachary Popowcer',
        text:'Chief Administrative Officer',
        bio: 'With a bachelor’s degree in criminal justice from the University of Nevada, Las Vegas, experience with legal writing, and extensive legal research capabilities, Zachary keeps Loan Shark Solutions, Inc. on the right track administratively.'
    },
    {
        id: 4,
        img:TeamShark4,
        title:'Daniel Gammal',
        text:'Chief Technical Officer',
        bio: 'With over 15 years of experience in software development, and an extensive background building & maintaining high traffic websites, Daniel uses his skills to bring Loan Shark Solutions, Inc. to the next stage technologically.'
    },
    {
        id: 5,
        img:TeamShark5,
        title:'Jason Sablan',
        text:'Marketing',
        bio: 'Jason can only be described as motivated and dedicated. With his vast range of experiences from military service, a technical, and mechanical background, he provides unique marketing ideas in addition to perspectives that push the team to succeed in all tasks.'
    },
    {
        id: 6,
        img:TeamShark6,
        title:'James "theVIKING" Jordan',
        text:'Marketing',
        bio: 'With his attention to detail, and extensive background in both marketing and development, James utilizes his unique skills, talents, and experience by bringing Loan Shark Solutions, Inc. to the highest stage possible.'
    },
]

export const PartnersData = [
    // {img:HomeDemo2Partners2},
    // {img:HomeDemo2Partners3},
    {
        title: 'MetaDock',
        text: 'MetaDock is a split-screen productivity app that lets you dock multiple browser windows vertically and horizontally in your layout as you see fit, making perfect use of your monitor real estate.',
        url: "https://www.metadock.org",
        img:HomeDemo2Partners1
    },
    // {img:HomeDemo2Partners4},
    // {img:HomeDemo2Partners5},
]

export const BlogPost = [
    {img:HomeDemo2BlogImg1},
    {img:HomeDemo2BlogImg2},
    {img:HomeDemo2BlogImg3}
]
