import {useEffect} from 'react'

import {Logo} from '../../data/data-layout/data-Header.js';

import {Addshrink , addActiveClass , OpenMenu , moveSmooth} from "../../utils/"

import './header.css'

import Preloader from '../../components/Preloader'

const Header = () => {

  useEffect(() => {
      Addshrink()
  },[])

  useEffect(() => {
      OpenMenu()
  },[])

  useEffect(() => {
      moveSmooth()
  },[])

  return (
    <>
      <Preloader />
      <header className="header-area wow fadeInDown" data-wow-delay="0.2s">
        <div className="classy-nav-container breakpoint-off">
          <div className="container">
            <nav className="classy-navbar justify-content-between" id="dreamNav">
              <a className="nav-brand" href="#"><img src={Logo} alt="logo" /></a>
              <div className="classy-navbar-toggler">
                <span className="navbarToggler" onClick={addActiveClass}>
                    <span />
                    <span />
                    <span />
                </span>
              </div>
              <div className="classy-menu">
                {/*<div className="classycloseIcon">*/}
                {/*  <div className="cross-wrap" onClick={addActiveClass}>*/}
                {/*      <span className="top" />*/}
                {/*      <span className="bottom" />*/}
                {/*  </div>*/}
                {/*</div>*/}
                <div className="classynav">
                  <ul id="nav">
                    <li><a onClick={moveSmooth} href="#home">Home</a></li>
                    <li><a onClick={moveSmooth} href="#partners">Partners</a></li>
                    <li><a onClick={moveSmooth} href="#mission">Mission</a></li>
                    <li><a onClick={moveSmooth} href="#timeline">Timeline</a></li>
                    <li><a onClick={moveSmooth} href="#team">Team</a></li>
                      <li><a data-toggle="modal" data-target="#tokenomics-modal">Tokenomics</a></li>
                      <li><a data-toggle="modal" data-target="#coming-soon-modal">WalletConnect</a></li>
                    <li><a href="https://drive.google.com/file/d/10PMMqyjmPV1ivwhO0JHW3ROglV5OzNb0/view" target="_blank">White paper</a></li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;

