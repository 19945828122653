const SecIco = ({logo}) => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="footer-copywrite-info">
                        <div className="copywrite_text wow fadeInUp" data-wow-delay="0.2s">
                            {/*<img src={logo} alt="logo" />*/}

                        </div>
                        {/* Social Icon */}
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12 text-center">
                    <p id="copyright-text">Copyright &copy; 2022 Loan Shark Solutions, Inc.</p>
                </div>
            </div>
        </div>
    )
}

export default SecIco
