import SectionHeading from '../../../components/SectionHeading'

const SecPartners = ({data}) => {

  return (
    <section className="partners center-block" id="partners">

        <SectionHeading
            title='Our partners'
            text=''
            numDots={1}
        />

        <div className="container">
            <div className="row">
                {data && data.map((item , key) => (
                    <div className="col-12 space-sides" data-aos="fade-up" key={key}>
                        <div className="partner-box">
                            <a href={item.url} target="_blank"><img src={item.img} alt="MetaDock" /></a>
                            <a href={item.url} target="_blank"><h4>{item.title}</h4></a>
                            <a href={item.url} target="_blank"><p>{item.text}</p></a>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </section>
  );
}

export default SecPartners;
