import SectionHeading from '../../../components/SectionHeading'
import { Chart } from "react-google-charts";

const pieData = [
    ["Type of percentage", "Percentage"],
    ["Private Sale", 0.1],
    ["Staking", 3],
    ["Founders", 5],
    ["Pre-Sale", 16],
    ["Development", 13],
    ["Marketing", 13],
    ["Future Sales", 50]
];

const options = {
    is3D: true,
    titleTextStyle: { color: '#FFF' },
    legendTextStyle: { color: '#FFF' },
    backgroundColor: {
        fill: 'none'
    },
    tooltip: {
        text: 'percentage'
    }
};

const SecModal = ({data}) => {
    return (
        <div>

            <div className="modal fade team-modal" id="coming-soon-modal" tabIndex="-1" aria-hidden="true" role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">WalletConnect unavailable</h5>
                        </div>
                        <div className="modal-body">Available in Stage II</div>
                    </div>
                </div>
            </div>

            <div className="modal fade team-modal" id="tokenomics-modal" tabIndex="-1" aria-hidden="true" role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Tokenomics</h5>
                        </div>
                        <div className="modal-body">
                            <Chart
                                id="tokenChart"
                                chartType="PieChart"
                                data={pieData}
                                options={options}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
        //
        // <div>
        //     {data && data.map((item, key) => (
        //         <div className="modal fade team-modal" id={"team-modal-" + item.id} tabIndex="-1" aria-hidden="true" role="dialog">
        //             <div className="modal-dialog">
        //                 <div className="modal-content">
        //                     <div className="modal-header">
        //                         <h5 className="modal-title">{item.title} - {item.text}</h5>
        //                         <button type="button" className="close" data-dismiss="modal" aria-label="Close"><span
        //                             aria-hidden="true">&times;</span></button>
        //                     </div>
        //                     <div className="modal-body">{item.bio}</div>
        //                     <div className="modal-footer">
        //                         <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     ))}
        // </div>
    // )
}

export default SecModal;
