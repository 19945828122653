const SecVideoArea = ({img}) => {

  return (
    <div className="col-lg-12" data-aos="fade-up">
        <div className="welcome-video-area">
            <video src="video.mp4" controls></video>
        </div>
    </div>
  );
}

export default SecVideoArea;
