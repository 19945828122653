const SecContent = () => {
	return(
        <div className="col-12 col-md-7">
          {/* Content Info */}
          <div className="contact_info_area d-sm-flex justify-content-between">
            <div className="contact_info text-center wow fadeInUp" data-wow-delay="0.2s">
              <h5>NAVIGATE</h5>
              <a href="#"><p>Home</p></a>
              <a href="#partners"><p>Partners</p></a>
              <a href="#mission"><p>Mission</p></a>
              <a href="#timeline"><p>Timeline</p></a>
              <a href="#team"><p>Team</p></a>
            </div>
            {/* Content Info */}
            <div className="contact_info text-center wow fadeInUp" data-wow-delay="0.3s">
              <h5>PRIVACY &amp; TOS</h5>
              <a href="https://drive.google.com/file/d/1-xSBVjBs39JxdV9xmTTT8YSarT4rL1mo/view?usp=drivesdk" target="_blank"><p>Terms & Conditions</p></a>
              <a href="https://drive.google.com/file/d/1-yMlJ1rq8R1wKviZdJz2_ufjaA_EC1Sf/view?usp=drivesdk" target="_blank"><p>Privacy Policy</p></a>
              <a href="https://drive.google.com/file/d/1M5Ok8yVPOPDl60jA3i2LLNw1tv6IyUKO/view?usp=drivesdk" target="_blank"><p>Disclaimer</p></a>
            </div>
            {/* Content Info */}
            <div className="contact_info text-center wow fadeInUp address" data-wow-delay="0.4s">
              <h5>Contact</h5>
              <p>Loan Shark Solutions, Inc.</p>
              <p>9750 W Skye Canyon Dr</p>
              <p>Suite 160-115. Las Vegas, Nevada 89166</p>
              <p>support@loansharksolutions.com</p>
            </div>
          </div>
        </div>
	)
}

export default SecContent
