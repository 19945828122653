import SectionHeading from '../../../components/SectionHeading'
import SecVideoArea from './SecVideoArea'

const SecDemoVideo = ({img}) => {

  return (
    <section className="demo-video">
        <SectionHeading
            className={"col-12"}
            title='A Financial Evolution'
            text=''
            numDots={5}
        />
        <div className="container">
            <div className="row">
                <SecVideoArea img={img} />
            </div>
        </div>
    </section>
  );
}

export default SecDemoVideo;
