import {useEffect}  from "react";
import './style/HomeDemo2.scss'

import {
          VerticalSocial,
          SingleCoolFact,
          service_single_content,
          timelineInfo,
          ServiceBlock,
          SocialListIco,
          FQAInfo,
          DocElementTitle,
          TokenText,
          TeamMember,
          PartnersData
       } from '../../data/data-containers/data-HomeDemo2.js';

import {
          HomeDemo2About1,
          HomeDemo2Solution,
          HomeDemo2VideoBg4,
          HomeDemo2ImgPhone,
          HomeDemo2RingsBg,
          HomeDemo2Allocation,
          HomeDemo2BgRoadmap
        } from '../../utils/allImgs'

import {handelTitle} from '../../utils'
import Header from "../../layouts/Header"
import Footer from '../../layouts/FooterPages'

import SecWelcomeArea from './SecWelcomeArea'
import SecVerticalSocial from './SecVerticalSocial'
// import SecTrust from './SecTrust'
import SecDemoVideo from './SecDemoVideo'
import SecOurServices from './SecOurServices'
import SecOurRoadmap from './SecOurRoadmap'
// import SecOurFeatures from './SecOurFeatures'
import SecSubscribe from './SecSubscribe'
import SecFAQ_Timeline from './SecFAQ_Timeline'
// import SecDistribution from './SecDistribution'
import SecTeam from './SecTeam'
import SecPartners from './SecPartners'
import SecModal from './SecModal'


let HomeDemo2 = () => {

  useEffect(() => {
    handelTitle('Loan Shark Solutions')
  },[])

  useEffect(() => {
      document.getElementsByTagName("body")[0].style.backgroundImage = 'linear-gradient(to right, #1d0498, #341f97)'
  },[])

  return (
    <>
       <Header />
      <div className="HomeDemo2">
        <SecWelcomeArea />
          <SecPartners data={PartnersData} />
        <SecVerticalSocial data={VerticalSocial} />
        {/*<SecTrust data={SingleCoolFact} />*/}
        {/*<SecAboutUsClient img={HomeDemo2About1} />*/}
        <div className="clearfix" />
        <SecOurServices data={service_single_content} />
        <SecOurRoadmap data={timelineInfo} img={HomeDemo2BgRoadmap} />
        {/*<SecOurFeatures data={ServiceBlock} imgPhone={HomeDemo2ImgPhone} Rings={HomeDemo2RingsBg} />*/}
        {/*<SecDistribution data={TokenText} />*/}
        <SecTeam data={TeamMember} />
        <SecDemoVideo img={HomeDemo2VideoBg4} />
          <SecSubscribe data={SocialListIco} />
          <SecModal />
        {/*<SecFAQ_Timeline FQAInfo={FQAInfo} DocElementTitle={DocElementTitle} />*/}
      </div>
      <Footer />
    </>
  );
};

export default HomeDemo2
