import SectionHeading from '../../../components/SectionHeading'

const SecTeam = ({data}) => {

  return (
    <section className="our_team_area section-padding-0-0 clearfix" id="team">
        <div className="container">
            <div className="row">
                <div className="col-12" >
                    <SectionHeading
                        className="clear-margin-padding"
                        title='Introducing the Team'
                        text=''
                        numDots={4}
                    />
                </div>
            </div>

            <div className="row">
                {data && data.map((item , key) => (
                    <div data-toggle="modal" data-target={"#team-modal-" + item.id} className="col-12 col-md-3 col-sm-12 team-container mr-2 ml-auto" key={key}>
                        <div className="single-team-member">
                            {/*<h5 className="text-center"></h5>*/}
                            <div className="team-member-thumb">
                                <img src={item.img} className="center-block" alt=""  />
                            </div>
                            <div className="team-info">
                                <p>{item.title} <br/> {item.text}</p>
                            </div>
                        </div>
                    </div>
                ))}

                <div>
                    {data && data.map((item, key) => (
                        <div className="modal fade team-modal" id={"team-modal-" + item.id} tabIndex="-1" aria-hidden="true" role="dialog">
                            <div className="modal-dialog">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">{item.title} - {item.text}</h5>
                                    </div>
                                    <div className="modal-body">{item.bio}</div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    </section>
  );
}

export default SecTeam;
