const Li_A = ({href, nameIco}) => <li><a href={href} target="_blank"><i className={nameIco} aria-hidden="true"></i></a></li>

const SecVerticalSocial = ({data}) => {

  return (
    <div className="vertical-social">
        <ul>
            {data && data.map((item , key) => (
                <Li_A nameIco={item.nameIco} href={item.href} />
                ))
            }

            <li><a data-toggle="modal" data-target="#coming-soon-modal"><img width="22px" src="walletconnect.png" alt=""></img></a></li>
        </ul>
    </div>
  );
}

export default SecVerticalSocial;
