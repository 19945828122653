const Content = ({img , title, text}) => {

  return (
    <div className="col-12" data-aos="fade-up">

        <div className="service_single_content text-center">

            <div className="service_icon">
                <img src={img} alt="" />
            </div>
            <h6>{title}</h6>
            <p>{text}</p>
        </div>
    </div>
  );
}

export default Content;
