const Li_A = ({nameIco}) => <li><a href="#"><i className={nameIco} aria-hidden="true"></i></a></li>
const metaMask = require("../../../assets/img/core-img/walletconnect.png")

const SecVerticalSocial = ({data}) => {

  return (
    <div className="vertical-social">
        <ul>
            {data && data.map((item , key) => (
                    <Li_A nameIco={item.nameIco} />
                ))
            }

            <li><a data-toggle="modal" data-target="#coming-soon-modal"><i className="fa fa-google-wallet" aria-hidden="true"></i></a></li>
        </ul>
    </div>
  );
}

export default SecVerticalSocial;
