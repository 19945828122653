function SecWelcomeContent({img}){
  return(
      <div className="welcome-content">
          {/*<div className="promo-section">*/}
          {/*    <div className="integration-link">*/}
          {/*        <span className="integration-icon">*/}
          {/*            <img src={img} width="24" height="24" alt="" />*/}
          {/*        </span>*/}
          {/*        <span className="integration-text" data-toggle="modal" data-target="#coming-soon-modal">Connect your wallet</span>*/}
          {/*    </div>*/}
          {/*</div>*/}
          <h1 id="shark-text"><b>$</b>SHARK Token</h1>
          <p id="shark-text-2">
              Loan Shark Solutions, Inc. will be your one stop shop for NFT lending and borrowing. We are focused on empowering users on a peer-to-peer network in which each party gets to set, and agree to, terms and conditions surrounding NFT lending and rentals. This will all be possible with the power of Stellar based smart contracts designed by "YOU." Our goal is to unlock and recycle funds through digital assets that would have otherwise sat and collected dust, while also providing the ultimate user experience. Conduct business on your terms, be in charge of your digital assets, be your own Loan Shark!
          </p>
          <p id="stellarText">Built on <b>Stellar</b>, created for <b>all.</b></p>
      </div>
  )
}

export default SecWelcomeContent
